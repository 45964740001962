import CryptoJS from 'crypto-js'
export default {
	//AES加密
	encryptAes(data) {
		let aesKey = '9fS8mJs4Ysyi2pb2F9HeaA==';

		const secretPassphrase = CryptoJS.enc.Utf8.parse(aesKey)
		// const iv = CryptoJS.enc.Utf8.parse('DYgjCEIMVrj2W9xN')
		const iv = CryptoJS.enc.Utf8.parse(aesKey.substring(0, 16))
		const message = CryptoJS.enc.Utf8.parse(data)

		const encrypted = CryptoJS.AES.encrypt(message, secretPassphrase, {
			mode: CryptoJS.mode.CBC,
			paddding: CryptoJS.pad.Pkcs7,
			iv: iv
		}).toString()
		return encrypted
	},
	//AES解密
	decryptAes(data) {
		let aesKey = '9fS8mJs4Ysyi2pb2F9HeaA==';
		const secretPassphrase = CryptoJS.enc.Utf8.parse(aesKey)
		const iv = CryptoJS.enc.Utf8.parse(aesKey.substring(0, 16))
		// const message = CryptoJS.enc.Utf8.parse('977Fr4BHpVSFI1fVwAcuLbpjAqP2I5kray34X5UWmLnEruHVWtsY2lbeNWuPgPxA:')
		// const message = CryptoJS.enc.Utf8.parse(data)
		const decrypted = CryptoJS.AES.decrypt(data, secretPassphrase, {
			mode: CryptoJS.mode.CBC,
			paddding: CryptoJS.pad.Pkcs7,
			iv: iv
		}).toString(CryptoJS.enc.Utf8)
		// console.log(decrypted+',', 4444444)
		return decrypted
	}
}
