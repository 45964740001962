import Mock from 'mockjs'
// 引入接口模板
import { getZzk, getMess, getRegister, getRegisterRig, getRegisterSw } from './mockZzk'
import { getRegisterDayNum, getRegisterDayNumAfter, peopleMan, peopleGh, getVisitDocter } from './data/home'

Mock.setup({
	// timeout: '1000',
})

// 首页 获取切换人列表
Mock.mock(/\/zzkTest/, getZzk)
// 消息列表
Mock.mock(/\/messList/, getMess)
// 挂号信息
Mock.mock(/\/registerListRig/, getRegisterRig)
Mock.mock(/\/registerList/, getRegister)
Mock.mock(/\/Morning/, getRegisterDayNum)
Mock.mock(/\/After/, getRegisterDayNumAfter)
// 出诊医生
Mock.mock(/\/Docter/, getVisitDocter)
// 就诊人管理
Mock.mock(/\/peopleList/, peopleMan)
// 挂号
Mock.mock(/\/peopleGhList/, peopleGh)

export default Mock
