const localStorageName = "ziGuiStore";

function getSessionStore() {
    const store = sessionStorage.getItem(localStorageName);
    if (store) {
        try {
            return JSON.parse(store);
        } catch (error) {
            return {};
        }
    }
    return {};
}

const storage = {
    // 获取某个参数
    getItem(key) {
        const store = getSessionStore();
        if (key) {
            return store[key];
        } else {
            return {}
        }
    },
    // 设置某个参数
    setItem(key, value) {
        let store = getSessionStore();
        if (key) {
            store[key] = value;
        }
        sessionStorage.setItem(localStorageName, JSON.stringify(store));
    },
    // 删除某个参数
    removeItem(key) {
        const store = getSessionStore();
        if (key) {
            delete store[key];
        }
        sessionStorage.setItem(localStorageName, JSON.stringify(store));
    },
};

export default storage;