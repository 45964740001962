// 高平线上
const appid = 'wxcacfd877f118b17e' //在上一步你用来注册成测试号的appid（注意：这里要和你登录的微信开发者账号的appid一致，也就是要用测试号来开发）
const local = 'http://www.gaopingszyyy.com' //要加http，要加端口号
    // 测试
    // const appid = 'wxcacfd877f118b17e' //在上一步你用来注册成测试号的appid（注意：这里要和你登录的微信开发者账号的appid一致，也就是要用测试号来开发）
    // const local = 'http://www.gaopingszyyy.com/wx' //要加http，要加端口号
    // JX 测试
    // const appid = 'wxfaef98f584adafad' //在上一步你用来注册成测试号的appid（注意：这里要和你登录的微信开发者账号的appid一致，也就是要用测试号来开发）
    // const local = 'http://192.168.10.19:8080' //要加http，要加端口号

/*
 *医保地址
 */
// 线上医保访问地址
const onlineAdress = 'https://card.wecity.qq.com/oauth/code'
const lineYbUrl = 'http%3A%2F%2Fwww.gaopingszyyy.com%2F%23%2F'

// 线上医保测试访问地址
// const onlineAdress = 'https://card.wecity.qq.com/oauth/code'
//  const lineYbUrl= 'http%3A%2F%2Fwww.gaopingszyyy.com%2Fwx%2F%23%2F'
// 测试医保访问地址
// const  outlineAdress= 'https://mitest.wecity.qq.com/oauth/code'
// 本地医保测试
// const  lineYbUrl= 'http%3A%2F%2F192.168.10.80%3A8080%2F'

// const serviceUrl = require('../../public/commonIp.js')
// 本地
// const PRO_URL = serviceUrl.ipConfigUrl.ip
// 线上
const PRO_URL = '/api'
    // 线上测试
    // const PRO_URL = '/api/stg'

const config = {
    appid,
    local,
    lineYbUrl,
    onlineAdress,
    PRO_URL
}

export default config